// extracted by mini-css-extract-plugin
export var container = "AddResidence-module--container--b2fuY";
export var header = "AddResidence-module--header--bnHW0";
export var content = "AddResidence-module--content--dzzWz";
export var form = "AddResidence-module--form--6fMbR";
export var form__section = "AddResidence-module--form__section--AR3Os";
export var form__section__item = "AddResidence-module--form__section__item--Ptb2v";
export var form__group = "AddResidence-module--form__group--k72mn";
export var form__group__input = "AddResidence-module--form__group__input--pKNPp";
export var heading = "AddResidence-module--heading---FjUN";
export var select = "AddResidence-module--select--065TT";
export var submit = "AddResidence-module--submit--MQ0mr";
export var resident = "AddResidence-module--resident--dexg+";
export var upload = "AddResidence-module--upload--+e3lS";