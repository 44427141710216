import { useMutation, useQuery } from "@apollo/client";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { navigate } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import BackButton from "../../images/back_arrow_icon@2x.png";
import { CreateResidanceQuery, Tower } from "../../Interfaces/ParkingArea";
import {
  CreateAreaTowerQuery,
  GetCustomTowerQuery,
} from "../../Query/ParkingArea";
import Logger from "../../utils/Logger";
import Server from "../../utils/Server";
import { UploadMedia } from "../../utils/UploadFile";

import Loader from "../Loader";

import * as AddResidenceStyle from "./AddResidence.module.scss";

interface Props {
  areaId: string;
}

interface Inputs {
  towerName: string;
  flatName: string;
  ownerName: string;
  ownerNumber: string;
  ownerGender?: string;
  ownerEmail?: string;
  tenentName: string;
  tenentNumber: string;
  tenentGender?: string;
  tenentEmail?: string;
}

interface IAddMember {
  contactNumber?: string;
  name?: string;
  email?: string;
  gender?: string;
  type?: "RO" | "RT";
}

const Index = ({ areaId }: Props) => {
  const { register, handleSubmit } = useForm();
  const [isExistingTower, setIsExistingTower] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    data,
    loading,
    error,
    refetch: refechTower,
  } = useQuery<{ custom_collection_queryResidences: { collection: Tower[] } }>(
    GetCustomTowerQuery,
    {
      variables: {
        parkingArea: `/parking_areas/${areaId}`,
      },
    }
  );

  const [
    createResidence,
    { loading: createResidenceLoading, error: createResidenceError },
  ] = useMutation<CreateResidanceQuery>(CreateAreaTowerQuery);

  useEffect(() => {
    const err = createResidenceError || error;
    if (err) Logger.showError(err);
  }, [error, createResidenceError]);

  const addResidanceMember = (residanceId: string, data: IAddMember) => {
    return new Promise((resolve, reject) => {
      const shortResidanceId = residanceId.split("/")[2];
      setIsLoading(true);
      Server.post(`/residence/${shortResidanceId}`, {
        name: data.name,
        contactNumber: data.contactNumber,
        type: data.type,
        email: data.email,
      })
        .then((res: any) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => setIsLoading(false));
    });
  };

  const onHandleSubmit: SubmitHandler<Inputs> = (data) => {
    if (data.towerName === "")
      return Logger.showError(new Error("Select Tower Name"));
    createResidence({
      variables: {
        parkingArea: `/parking_areas/${areaId}`,
        towerName: data.towerName,
        flatName: data.flatName,
      },
    })
      .then((res) => {
        const resiId = res.data?.createResidence.residence.id;
        if (resiId) {
          setIsLoading(true);
          const userData: IAddMember[] = [
            {
              name: data.ownerName,
              contactNumber: data.ownerNumber,
              email: data?.ownerEmail,
              gender: data?.ownerGender,
              type: "RO",
            },
            {
              name: data?.tenentName,
              contactNumber: data?.tenentNumber,
              email: data?.tenentEmail,
              gender: data?.tenentGender,
              type: "RT",
            },
          ];

          const userPromises = userData
            .filter(
              ({ contactNumber, name }) => name && contactNumber?.length === 10
            )
            .map((data) => {
              return new Promise((resolve, reject) => {
                addResidanceMember(resiId, data)
                  .then((res) => {
                    resolve(res);
                  })
                  .catch((err) => reject(err));
              });
            });

          Promise.all(userPromises)
            .then(() => {
              navigate(-1);
            })
            .catch((err) => {
              Logger.showError(err);
            })
            .finally(() => setIsLoading(false));
        }
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(data);
  };

  const uploadCsv = (file: File) => {
    return new Promise((resolve, reject) => {
      UploadMedia({
        name: file?.name,
        path: file,
        type: file?.type,
      })
        .then(({ key }) => {
          Server.post(
            `/parking_area/${areaId}/residences/excel_import`,
            {},
            {
              keyname: key,
            }
          )
            .then((res) => {
              navigate(-1);
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  return (
    <>
      <div className={AddResidenceStyle.container}>
        <div className={AddResidenceStyle.header}>
          <button onClick={() => navigate(-1)}>
            <img src={BackButton} alt="back" width={32} height={32} />
          </button>
        </div>
        <div className={AddResidenceStyle.content}>
          <form
            onSubmit={handleSubmit(onHandleSubmit)}
            className={AddResidenceStyle.form}
          >
            <div className={AddResidenceStyle.form__section}>
              <div className={AddResidenceStyle.resident}>
                <span className={AddResidenceStyle.heading}>Resident:</span>
                {data?.custom_collection_queryResidences.collection.length ? (
                  <div>
                    <input
                      onChange={(e) => setIsExistingTower(e.target.checked)}
                      id="existing"
                      type="checkbox"
                      checked={isExistingTower}
                    />
                    <label htmlFor="existing">Existing Tower</label>
                  </div>
                ) : (
                  ""
                )}
                <label htmlFor="csvfile" className={AddResidenceStyle.upload}>
                  <FontAwesomeIcon icon={faUpload} />
                  <span>Upload Excel</span>
                </label>
                <input
                  id="csvfile"
                  type="file"
                  hidden
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={(e) => {
                    if (e.target.files) {
                      setIsLoading(true);
                      uploadCsv(e.target.files[0])
                        .then((res) => {
                          console.log(res);
                        })
                        .catch((err) => {
                          Logger.showError(err);
                        })
                        .finally(() => setIsLoading(false));
                    }
                  }}
                />
              </div>
              <div className={AddResidenceStyle.form__section__item}>
                <div className={AddResidenceStyle.form__group}>
                  <label htmlFor="tower">Tower Name</label>
                  {isExistingTower ? (
                    <select
                      className={AddResidenceStyle.select}
                      id="tower"
                      {...register("towerName")}
                      required
                      placeholder="Select Tower"
                    >
                      <option value="">Select Tower</option>
                      {data?.custom_collection_queryResidences.collection.map(
                        ({ towerName }, i) => (
                          <option key={`${towerName}/${i}`} value={towerName}>
                            {towerName}
                          </option>
                        )
                      )}
                    </select>
                  ) : (
                    <input
                      id="tower"
                      className={AddResidenceStyle.form__group__input}
                      type="text"
                      placeholder="Enter Tower Name"
                      required
                      {...register("towerName")}
                    />
                  )}
                </div>
                <div className={AddResidenceStyle.form__group}>
                  <label htmlFor="flat">Flat Name</label>
                  <input
                    id="flat"
                    className={AddResidenceStyle.form__group__input}
                    type="text"
                    placeholder="Enter Flat Name"
                    required
                    {...register("flatName")}
                  />
                </div>
              </div>
            </div>
            <div className={AddResidenceStyle.form__section}>
              <span className={AddResidenceStyle.heading}>Resident Owner:</span>
              <div className={AddResidenceStyle.form__section__item}>
                <div className={AddResidenceStyle.form__group}>
                  <label htmlFor="ownername">Owner Name</label>
                  <input
                    id="ownername"
                    className={AddResidenceStyle.form__group__input}
                    type="text"
                    placeholder="Enter Name"
                    required
                    {...register("ownerName")}
                  />
                </div>
                <div className={AddResidenceStyle.form__group}>
                  <label htmlFor="ownernumber">Owner Contact Number</label>
                  <input
                    id="ownernumber"
                    className={AddResidenceStyle.form__group__input}
                    type="number"
                    placeholder="Enter Contact Number"
                    required
                    {...register("ownerNumber")}
                  />
                </div>
                <div className={AddResidenceStyle.form__group}>
                  <label htmlFor="owneremail">Owner Email</label>
                  <input
                    id="owneremail"
                    className={AddResidenceStyle.form__group__input}
                    type="email"
                    placeholder="Enter Email"
                    {...register("ownerEmail")}
                  />
                </div>
                <div className={AddResidenceStyle.form__group}>
                  <label htmlFor="ownergender">Owner Gender</label>
                  <select
                    {...register("ownerGender")}
                    className={AddResidenceStyle.select}
                    id="ownergender"
                    placeholder="Select Gender"
                  >
                    <option value="">Select Gender</option>
                    {["MALE", "FEMALE", "OTHER"].map((gender) => (
                      <option key={`${gender}/owner`} value={gender}>
                        {gender}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className={AddResidenceStyle.form__section}>
              <span className={AddResidenceStyle.heading}>
                Resident Tenent(Optional):
              </span>
              <div className={AddResidenceStyle.form__section__item}>
                <div className={AddResidenceStyle.form__group}>
                  <label htmlFor="tenentname">Tenent Name</label>
                  <input
                    id="tenentname"
                    className={AddResidenceStyle.form__group__input}
                    type="text"
                    placeholder="Enter Name"
                    {...register("tenentName")}
                  />
                </div>
                <div className={AddResidenceStyle.form__group}>
                  <label htmlFor="tenentnumber">Tenent Contact Number</label>
                  <input
                    id="tenentnumber"
                    className={AddResidenceStyle.form__group__input}
                    type="number"
                    placeholder="Enter Contact Number"
                    {...register("tenentNumber")}
                  />
                </div>
                <div className={AddResidenceStyle.form__group}>
                  <label htmlFor="tenentemail">Tenent Email</label>
                  <input
                    id="tenentemail"
                    className={AddResidenceStyle.form__group__input}
                    type="email"
                    placeholder="Enter Email"
                    {...register("tenentEmail")}
                  />
                </div>
                <div className={AddResidenceStyle.form__group}>
                  <label htmlFor="tenetgender">Tenent Gender</label>
                  <select
                    {...register("tenentGender")}
                    className={AddResidenceStyle.select}
                    id="tenetgender"
                    placeholder="Select Gender"
                  >
                    <option value="">Select Gender</option>
                    {["MALE", "FEMALE", "OTHER"].map((gender) => (
                      <option key={`${gender}/owner`} value={gender}>
                        {gender}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className={AddResidenceStyle.submit}>
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
      <Loader
        isLoading={loading || createResidenceLoading || isLoading || false}
      />
    </>
  );
};

export default Index;
